import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I recently posted on a web forum to launch my `}<a parentName="p" {...{
        "href": "https://feedwise.net"
      }}>{`newly Production-ready RSS reader app`}</a>{`. Someone checking out the post informed me that the app name I'd chosen was already taken in the RSS reader space. This is why I embarked on a mission to update the name and URL of my app.`}</p>
    <p>{`Let's dive into what it took to change the name of my web application.`}</p>
    <p>{`The steps below were taken for both the Staging and Production environments. They are specific to my application and infrastructure architecture. The application is a React SPA client deployed via Netlify with a Phoenix/Elixir API deployed via Fly IO.`}</p>
    <p>{`Here's what it took to get this job done:`}</p>
    <ol>
      <li parentName="ol">{`come up with the new name`}
        <ul parentName="li">
          <li parentName="ul">{`this was the longest part of the process for me`}</li>
          <li parentName="ul">{`shout out to my beautiful wife for coming up with the final name, FeedWise`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Update the application code to use the new app name instead of the old one`}
        <ul parentName="li">
          <li parentName="ul">{`nice to just have this done and ready to go`}</li>
          <li parentName="ul">{`this includes any logos and images with the old site name`}</li>
        </ul>
      </li>
      <li parentName="ol">{`buy the new domain via Netlify`}</li>
      <li parentName="ol">{`create a new Netlify site`}
        <ul parentName="li">
          <li parentName="ul">{`import the client GitHub repository`}</li>
          <li parentName="ul">{`set up environment variables with the new domain updates`}</li>
          <li parentName="ul">{`disable preview deploys (personal preference)`}</li>
          <li parentName="ul">{`deploy client`}</li>
        </ul>
      </li>
      <li parentName="ol">{`update the Netlify DNS configuration for the new site to use the new domain`}</li>
      <li parentName="ol">{`for the old Netlify site`}
        <ul parentName="li">
          <li parentName="ul">{`unlink the GitHub repository`}</li>
          <li parentName="ul">{`"Lock to stop auto publishing"`}</li>
        </ul>
      </li>
      <li parentName="ol">{`set up API to work with the new domain `}<strong parentName="li">{`as well as the old domain`}</strong>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`fly certs add api.new-domain.net -a <fly-app-name-for-api>`}</inlineCode></li>
          <li parentName="ul">{`add `}<inlineCode parentName="li">{`CNAME`}</inlineCode>{` record in Netlify DNS configuration page`}</li>
          <li parentName="ul">{`add new domain to CORS allow list and deploy the changes to the target environment`}</li>
        </ul>
      </li>
      <li parentName="ol">{`make sure the cert gets issued`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`fly certs show api.new-domain.net -a <fly-app-name-for-api>`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ol">{`update the `}<inlineCode parentName="li">{`public/_redirects`}</inlineCode>{` file in the client to automatically redirect users to the new domain if trying to navigate to the old URL`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`/* https://new-domain.net/:splat 301!`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ol">{`have any third party apps re-configured to point to the new domain instead of the old domain`}
        <ul parentName="li">
          <li parentName="ul">{`For example, OAuth providers like Google and GitHub`}</li>
          <li parentName="ul">{`Don't save the changes just yet! This is nice to reduce down-time. When the time comes just save the changes instead of having to fill out the forms.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`deploy the application code changes from step 2 to update the new name`}</li>
      <li parentName="ol">{`manually deploy `}<inlineCode parentName="li">{`public/_redirects`}</inlineCode>{` changes`}
        <ul parentName="li">
          <li parentName="ul">{`ensure the environment variables on your machine match what you'd expect in the target environment`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`yarn build`}</inlineCode></li>
          <li parentName="ul">{`copy the `}<inlineCode parentName="li">{`dist`}</inlineCode>{` directory from your local machine to Netlify for a manual deploy`}</li>
        </ul>
      </li>
      <li parentName="ol">{`save all 3rd party config updates from step 10`}</li>
      <li parentName="ol">{`test`}</li>
    </ol>
    <p>{`Steps 11-13 should be done together as fast as possible to reduce down-time.`}</p>
    <p>{`Some cleanup steps that should probably happen next:`}</p>
    <ul>
      <li parentName="ul">{`remove cert for old domain API`}</li>
      <li parentName="ul">{`plan to retire the old domain `}
        <ul parentName="li">
          <li parentName="ul">{`either via given timeline or when there is less than a certain amount of traffic being redirected`}</li>
        </ul>
      </li>
      <li parentName="ul">{`update bookmarked links`}</li>
      <li parentName="ul">{`update links in any published content like blog posts`}</li>
    </ul>
    <p>{`Some optional next steps:`}</p>
    <ul>
      <li parentName="ul">{`update GitHub repository names`}</li>
      <li parentName="ul">{`update infrastructure naming`}</li>
      <li parentName="ul">{`update all API namespace naming`}</li>
    </ul>
    <p>{`That's it! New app name, who dis?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      